<template>

  <div class="wrapper">
    <Slider></Slider>

    <div style="color: black;border-bottom: solid var(--light-gray) 1px; padding: 0 0.5rem;" class="d-block">
      <h5 style="font-weight:800;font-size: 15px">
        Popular Games
      </h5>
      <div class="games-container">
        <div v-for="(game, index) in homePagePopularGames" :key="getKey(index)" class="game-item">
          <div class="game-image-container">
            <div class="game-icon">
              <router-link
                :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: game.provider_id, gameName: game.game_name, gameID: game.game_id, launchURL: game.launch_url, launchType: game.launch_type, launchParameters: game.launch_parameters }, }">
                <img class="game-image" :src="game.image" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Sports></Sports>
    <div id="dp" class="stick date-filter">

      <div style="margin-bottom: 5px;background-color: white;" class="highlight-box pt-0">
        <section class="bets-menu-strip d-flex justify-content-between">

          <div v-if="highlights" class="w-20 p-1  active text-center">
            Highlights
          </div>
          <div v-else class="w-20 p-1  text-blue text-center" @click="setHighlight">
            <a class="text-dark" style="text-decoration: none;">Highlights</a>
          </div>

          <div v-if="today" class="w-20 p-1 active text-center">
            Today
          </div>
          <div v-else class="w-20 p-1  text-blue text-center" @click="setToday">
            <a class="text-dark" style="text-decoration: none;">Today</a>
          </div>

          <div v-if="tomorrow" class="w-20 p-1 active text-center">
            Tomorrow
          </div>
          <div v-else class="w-20 p-1  text-blue text-center" @click="setTomorrow">
            <a class="text-dark" style="text-decoration: none;">Tomorrow</a>
          </div>


          <div v-if="upcoming" class="w-20 p-1  active text-center">
            Upcoming
          </div>
          <div v-else class="w-20 p-1  text-blue text-center" @click="setUpcoming">
            <a class="text-dark" style="text-decoration: none;">Upcoming</a>
          </div>
          <div class="w-20 p-1 px-3 text-blue d-none">
            Chap! Pick
          </div>

        </section>
      </div>

      <div class="buttons-sec nav-fill ">
        <div class="dropdown pr-1">
          <Countries></Countries>
        </div>

        <div class="dropdown pr-1">
          <Leagues></Leagues>
        </div>

        <div class="dropdown pr-1">
          <Markets></Markets>
        </div>
      </div>
    </div>

    <Games v-bind:highlights="highlights" v-bind:today="today" v-bind:upcoming="upcoming" v-bind:tomorrow="tomorrow"
      v-bind:sport="sport_id">
    </Games>

    <BottomNavigation></BottomNavigation>
  </div>

</template>

<script>
const Slider = () => import('./Slider.vue')
// const Boosted = () => import('./Boosted.vue')
const Markets = () => import('./accordion/markets.vue')
const Sports = () => import('./accordion/sports.vue')
const Countries = () => import('./accordion/countries.vue')
const Leagues = () => import('./accordion/leagues.vue')
const Games = () => import('./Games.vue')
import casino from "@/services/casino";

const BottomNavigation = () => import('./BottomNavigation.vue')
import jQuery from "jquery";
const $ = jQuery;

export default {
  name: 'Sport',
  metaInfo: {
    title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
    meta: [
      { name: 'description', content: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!' },
      {
        name: 'keywords', content: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting,' +
          'Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In- play betting, Cash out, Live odds, Betting tips, Aviator,' +
          'Jetx, Cometcrash, Spin2wiin, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support,' +
          'Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score'
      }
    ]
  },
  components: {
    Leagues,
    Countries,
    Markets,
    Sports,
    Slider,
    Games,
    BottomNavigation
  },

  data: function () {
    return {
      highlights: this.$store.state.current_tab === 'highlights' || !this.$store.state.current_tab,
      upcoming: this.$store.state.current_tab === 'upcoming',
      tomorrow: this.$store.state.current_tab === 'tomorrow',
      today: this.$store.state.current_tab === 'today',
      sub_page: '',
      mqttClient: '',
      homePagePopularGames: [],
    }
  },
  mounted() {

    this.getCasinoGames();

    this.$store.dispatch("setHour", -1);
    this.$store.dispatch("setCompetitionID", 0);
    this.reloadProfile();
    this.$store.dispatch("setCurrentPage", 'sport');
  },
  computed: {
    betslip_count: function () {

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {

      return this.$store.state.sport_id;

    },

  },
  methods: {
    getRandomNumber() {
      return Math.floor(Math.random() * (9997 - 50 + 1)) + 50;
    },
    truncateGameName(name) {
      return name.length > 13 ? name.substring(0, 8) + '...' : name;
    },
    async fetchPopularGames() {
      try {
        const response = await casino.get(`${process.env.VUE_APP_CASINO_BASE_URL}/popular`);
        this.homePagePopularGames = response.data.data;
      } catch (error) {
        console.error("Error fetching popular games:", error);
      }
    },
    getLaunchURL: function (launch_url) {

      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;

    },

    getKey(index) {
      return `game_${index}`;
    },

    generateGameUrl(game) {
      return `/casinogames?IsDemo=0&providerID=${game.provider_id}&gameName=${game.game_name}&gameID=${game.game_id}&launchURL=${game.launch_url}&launchType=${game.launch_type}&launchParameters=${game.launch_parameters}`;
    },

    setUpcoming: function () {
      this.$store.dispatch("setCurrentTab", "upcoming");
      this.highlights = false;
      this.today = false;
      this.tomorrow = false;
      this.upcoming = true;
    },

    setToday: function () {
      this.$store.dispatch("setCurrentTab", "today");
      this.highlights = false;
      this.today = true;
      this.tomorrow = false;
      this.upcoming = false;
    },

    setTomorrow: function () {
      this.$store.dispatch("setCurrentTab", "tomorrow");
      this.highlights = false;
      this.today = false;
      this.tomorrow = true;
      this.upcoming = false;
    },

    setHighlight: function () {
      this.$store.dispatch("setCurrentTab", "highlights");
      this.highlights = true;
      this.today = false;
      this.tomorrow = false;
      this.upcoming = false;
    },
    setSubPage: function (page) {

      this.$store.dispatch("setCurrentSubPage", page);
      this.sub_page = page;

    },
    isHighlight: function () {

      return this.highlights
    },
    isToday: function () {

      return this.today
    },
    isUpcoming: function () {

      return this.upcoming
    },

    isTomorrow: function () {
      return this.tomorrow
    },

    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    setSport: function (sportID) {

      this.$store.dispatch("setSportID", sportID);

    }
  },
  created() {
    this.fetchPopularGames();
  }
}


window.addEventListener("scroll", () => {
  //var viewportOject = $('#infoY').offset().top - 50;
  var scrollPos = $(document).scrollTop();
  //console.log("vv"+viewportOject);
  //console.log("SS"+scrollPos);
  if (scrollPos >= 150) {
    // $("#dp").addClass("stick");
    //alert("I am here");
  } else {
    // $("#dp").removeClass("stick");
  }
})

</script>

<style scoped>
.game-icon {
  position: relative;
  width: 100px;
  height: 100px;
}
.random-number {
  position: absolute;
  top: 3px;
  right: 10px;
  background-color: #e0f807bd;
  padding: 0px 3px;
  border-radius: 5px;
  font-size: 12px;
  max-width: 100px;
  overflow: hidden;
  color: #101B21 !important;
  font-weight: 700;
}

.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding-bottom: 10px;
}

.horizontal-scroll::-webkit-scrollbar {
  height: 8px;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 4px;
}


.game-item {
  min-width: 104px;
  flex: 0 0 auto;
  text-align: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.games-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  gap: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.games-container::-webkit-scrollbar {
  display: none;
}

.game-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.game-image-container {
  width: 100px;
  height: 100px;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);

}

.list-active {
  background-color: #F08B05;
}

.custom-divider {
  border-top: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
}

.mobile-container {
  display: flex;
  flex-wrap: wrap;
}

.mobile-top-left-corner {
  background-color: white;
  border-top: 1.5px solid rgba(209, 201, 201, 0.979);
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-top-left-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

.mobile-top-right-corner {
  background-color: white;
  border-top: 1.5px solid rgba(209, 201, 201, 0.979);
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-right: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-top-right-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

.mobile-bottom-left-corner {
  background-color: white;
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom-left-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

.mobile-bottom-right-corner {
  background-color: white;
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-right: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom-right-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

span {
  font-weight: 700;
}

.date-filter {
  padding: 5px 0.5rem;
  border-top: 1px var(--light-gray) solid;
  border-bottom: 1px var(--light-gray) solid;
}
</style>